
























































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Post, subchannels} from '~/components/community/community-models'
import {toInstanceForce} from '~/utils/Serializer'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import CloseUpImage from '~/components/closeup-image.vue'
import subjects from '~/data/subjects'
import {Channels, ChannelName} from '@afterschool.dev/as-data-admin'

@Component({
    components: {
        CloseUpImage
    },
    metaInfo() {
        return {
            title: 'Community Posts'
        }
    }
})
export default class CommunityPosts extends mixins(QueryParserMixin, PaginationMixin) {
    ChannelName = ChannelName
    Channels = Channels
    subchannels = subchannels

    // filter
    member_id: string = ''
    channel: number = -1
    solved: 'true' | 'false' | '' = ''
    blocked: '' | 'hide' | 'only' = ''
    sort: 'last_commented' | '' = ''
    from: Date | null = null
    to: Date | null = null

    posts: Post[] = []
    closeUpURL = ''

    loading = true

    subjectsOp: { value: number, label: string }[] = []

    tableRowClassName({row}) {
        const post: Post = row
        if (post.blocked) {
            return 'post-blocked'
        }
        if (post.best_answer === '' && post.style === 1)
            return 'post-unsolved'
        return ''
    }

    dateToStr(date: Date | null): string | undefined {
        if (!date)
            return undefined
        return date.toLocaleDateString('en-GB').replace(/\//g, '-')
    }

    strToDate(str: string): Date {
        const splited = str.split('-')
        return new Date(
            Number(splited[2]),
            Number(splited[1]) - 1,
            Number(splited[0]))
    }

    // data query
    async toPage(pageNumber: number) {
        this.currentPage = pageNumber
        this.updatePosts()
    }

    updateFilter(backToFirstPage: boolean = true) {
        if (backToFirstPage)
            this.currentPage = 1
        this.updatePosts()
    }


    queryDef = [
        {localVar: 'member_id', queryStr: 'member-id'},
        {localVar: 'channel', type: QueryType.Number},
        {localVar: 'solved'},
        {localVar: 'blocked'},
        {localVar: 'sort'},
        {localVar: 'from', type: QueryType.Date},
        {localVar: 'to', type: QueryType.Date},
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    created() {
        const keys = Object.keys(subjects)
        keys.pop()
        this.subjectsOp = keys.map((key) => {
            return {value: Number(key) + 1000, label: subjects[key]}
        })

        this.parseQuery()
        this.updatePosts()
    }

    // mounted() {}
    async updatePosts() {
        const body: { [key: string]: any } = {}
        const param: { [key: string]: string } = {
            skip: (this.currentPage - 1) * this.step + '',
            limit: this.step + '',
            solved: this.solved,
            blocked: this.blocked || 'both',
            sort_by: this.sort,
            member_id: this.member_id,
            count: 'true'
        }
        if (this.member_id !== '') {
            param.member_id = this.member_id
        }
        if (this.from) {
            param.from_time = this.from.getTime().toString()
        }
        if (this.to) {
            param.to_time = this.to.getTime().toString()
        }

        if (this.channel !== -1) {
            if (this.channel >= 1000) {
                body.channel = [0, 1]
                body.subchannels = [this.channel - 1000]
            } else {
                body.channel = this.channel
            }
        }
        if (this.solved === 'false') {
            body.style = 1
        }
        this.loading = true
        try {
            const res = await createRequest('/community-v2/posts', 'post', param, body).send()
            const posts: Post[] = []
            for (const json of res.data.posts) {
                // posts.push(toInstanceForce(new Post(), json));
                const post = toInstanceForce(new Post(), json)
                if (post.content.length > 40)
                    post.content = post.content.substr(0, 40) + '...'
                posts.push(post)
            }
            this.posts = posts
            this.totalCount = res.data.count
            this.setQuery()
        } catch (e) {
            console.error(e)
            this.$message.error('Error')
        }
        this.loading = false
    }

    // blocking
    blockPost(postId: string) {
        createRequest('/community-v2/post/' + postId + '/block', 'patch', {}, {}).send()
            .then((_) => {
                const post = this.posts.find((p: Post) => {
                    return p._id == postId
                })
                if (post != undefined) {
                    post.blocked = true
                }
            })
    }

    unblockPost(postId: string) {
        createRequest('/community-v2/post/' + postId + '/unblock', 'patch', {}, {}).send()
            .then((_) => {
                const post = this.posts.find((p: Post) => {
                    return p._id == postId
                })
                if (post != undefined) {
                    post.blocked = false
                }
            })
    }
}
